/* eslint-disable filenames/match-exported */

import TransformerKitQuery from 'models/api/TransformerKitQuery'
import getProfileParser from 'workers/get-profile-parser.js'

import { ParseProfileData, serialize } from 'utils/api-utilities'

import {
  PrintLabelQuery,
  TAPICustomer,
  TAPILubePickListItem,
  TAPILubricant,
  TAPIMachine,
  TAPIPicklistItem,
  TAPIPlant,
  TAPIPlantContact,
  TAPIProfileDivision,
  TAPIRoute,
  TAPITestGroup,
  TFinancialAlert,
  TGetSpendingQuery,
  TPagedShipmentInformation,
  TSalesInvoice,
  TSalesOrders,
  TSampleIssues,
  TShipmentInformation,
  TShipmentInformationQuery,
  TSpendingEntity,
  TFinancialAlertsQuery,
  TBillingCustomer,
  TInvoiceQuery,
  TInvoicesPayment,
  TInvoicePaymentStatus,
  TAPILubricantManufacturer,
  Product,
  TPurchaseOrder,
  TPlantQuery as GetPlantsQuery,
  TSupplyOrderCreditCard,
  TSupplyOrderPurchaseOrder,
  TSupplyOrderFree,
  TSupplyOrderResponse,
  TCustomerProductPricing,
  TCustomerProductGroupEditing,
  TCustomerProductGroup,
  ProductGroup,
  GetBottleStatusQuery,
  PatchEncryptedBottleQuery,
  PatchBottleQuery,
  BottleStatusDetails,
  GetEncryptedBottlesQuery,
  GetBottlesQuery,
  GetBottlesResponse,
  GetEncryptedBottlesResponse,
  PatchBottlesResponse,
  PatchEncryptedBottlesResponse,
  PostPlantsQuery,
  PostMachinesQuery,
  PostPlantsResponse,
  PostMachinesResponse,
  GetEncryptedBottleStatusQuery,
  GetMachinesQuery,
  GetMachinesResponse,
  PutEncryptedWarhammerSamples,
  EncryptedWarhammerSample,
  PutWarhammerSamples,
  WarhammerSample,
  GetEncryptedWarhammerSamples,
  GetWarhammerSamples,
  GetSamples,
  Sample,
  GetPlantsResponse,
  ProblemDetails,
  GetCustomerByEncryptedBottleIdentifierQuery,
  GetCustomersQuery,
  GetCustomersResponse,
  CustomerResponseDTO,
  GetContactsByEncryptedBottleIdentifierQuery,
  GetContactsResponse,
  GetContactsQuery,
  GetCustomerPrimaryContactByEncryptedBottleIdentifierQuery,
  ContactDTO,
  GetCustomerPrimaryContact,
  TGetPlantsByEncryptedBottleQuery,
  PostPlantsByEncryptedBottleQuery,
  GetMachinesWithEncryptedBottleIdentifierQuery,
  ChangeUserPassword,
  GetBillingPlantsQuery,
  GetBillingPlantsResponse,
  GetAllFluidManufacturersEncrypted,
  GetAllMachineTypesEncrypted,
  GetAllMachineManufacturersEncrypted,
  TListType,
  FluidType,
  GetAllMachinePortTypesEncrypted,
  TIdentityType,
  GetFluidDetails,
  FluidDetails,
  GetFluidDetailsEncrypted,
  GetAllCustomerPricingEncrypted,
  TCustomerTestGroupPricing,
  GetRoutesEncrypted,
  Route,
  GetMachineDetail,
  GetMachineDetailEncrypted,
  PostMachineDTO,
  PostMachinesDTO,
  PatchMachinesQuery,
  PatchEncryptedMachinesQuery,
  PostEncryptedMachinesQuery,
  TNoSalesOrderQuery,
  TNoSalesOrderSample,
} from '../types/api'

import GraphQLService from './graphql-service'
import LoginService from './login-service'
import { CancellablePromise } from 'utils/CancellablePromise'
import { SupplyQuery } from 'models/api/SupplyQuery'
import { CustomerProductGroupQuery } from 'models/api/CustomerProductGroupQuery'
import { FetchError } from 'utils/Errors/FetchError'
import {
  getIsInternalUser,
  getIsInternalUserHeader,
} from 'utils/customer-utilities'

export interface User {
  cell?: string
  phone?: string
  firstName: string
  lastName: string
  loginID: string
  email?: string
  readOnly: boolean
  cCID: number
  uPSSI: boolean
  updatePassword: boolean
}

export type TProfile = {
  user: User
  correctiveActions: Array<TAPIPicklistItem>
  machineMfgOptions: Array<TAPIPicklistItem>
  machineTypeOptions: Array<TAPIPicklistItem>
  lubeMfrOptions: Array<TAPILubePickListItem>
  divisions: Array<TAPIProfileDivision>
  customers: Array<TAPICustomer>
  plants: Array<TAPIPlant>
  routes: Array<TAPIRoute>
  machines: Array<TAPIMachine>
  machineNames: string[]
  custEquIDs: string[]
  machineTypes: string[]
  machineNos: string[]
  machineMfgs: string[]
  machineModels: string[]
  lubeTypes: Array<TAPILubricant>
  testGroups: Array<TAPITestGroup>
  error?: string | null
  shippingInformation?: TShipmentInformation[]
  billingCustomers: TBillingCustomer[]
}

/*
 * GetUsers
 *GetDivisionsAsync
 *GetCustomers
 *GetCorrectiveActions
 *GetMachineTypes
 *GetMachineMfgs
 *GetLubeMfrs
 *GetTestGroups
 */

export default class APIService {
  // Queries

  static async getUsersAsync(): Promise<User> {
    const data = await GraphQLService.query(`
    query Users
    {
        user {
            firstName,
            lastName,
            loginID,
            readOnly,
            cCID
        }
    }`)
    return data.users
  }

  /**
   * Returns the batch's GUID
   */
  static async generateMachineLabelBatch(
    printLabelQuery: PrintLabelQuery
  ): Promise<string> {
    return await APIService.apiPost<PrintLabelQuery, string>(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/MachineLabel`,
      printLabelQuery
    )
  }

  // static async getDivisionsAsync(): Promise<Array<TAPIProfileDivision>> {
  //   const data = await GraphQLService.query(`
  //   query Divisions
  //   {
  //       divisions {
  //           iD,
  //           name,
  //           customers {
  //               custID
  //           }
  //       }
  //   }`)

  //   return data.divisions
  // }

  // static async getCustomersAsync(): Promise<Array<TAPICustomer>> {
  //   const data = await GraphQLService.query(`
  //   query Customers
  //   {
  //       customers {
  //           custID,
  //           companyID,
  //           custName,
  //           uPSSI,
  //           dBLogo,
  //           routes {
  //               routeID,
  //               routeNo,
  //               name
  //           },
  //           plants {
  //               plantID,
  //               plantName,
  //               plantNo,

  //           }
  //       }
  //   }`)

  //   return data.customers
  // }

  private static StringCompare = (a: string, b: string) => a.localeCompare(b)

  // static async getCorrectiveActionsAsync(): Promise<Array<TAPIPicklistItem>> {
  //   const data = await GraphQLService.query(`
  //   query CorrectActions
  //   {
  //       correctiveActions: listItems(listType: 73, locationID: 1) {
  //           listValue,
  //           listDesc
  //       }
  //   }`)

  //   return data.correctiveActions
  //     .sort((a, b) => this.StringCompare(a.listDesc, b.listDesc))
  //     .map(x => {
  //       return { listValue: x.listValue * 1, listDesc: x.listDesc }
  //     })
  // }

  // static async getMachineTypesAsync(): Promise<Array<TAPIPicklistItem>> {
  //   const data = await GraphQLService.query(`
  //   query Divisions
  //   {
  //       machineTypes: listItems(listType: 28, locationID: 1) {
  //           listValue,
  //           listDesc
  //       }
  //   }`)

  //   return data.machineTypes
  //     .sort((a, b) => this.StringCompare(a.listDesc, b.listDesc))
  //     .map(x => {
  //       return { listValue: x.listValue, listDesc: x.listDesc }
  //     })
  // }

  // static async getMachineMfgOptions(): Promise<Array<TAPIPicklistItem>> {
  //   const data = await GraphQLService.query(`
  //   query Divisions
  //   {
  //       machineMfgs: listItems(listType: 27, locationID: 1) {
  //           listValue,
  //           listDesc
  //       }
  //   }`)

  //   return data.machineMfgs
  //     .sort((a, b) => this.StringCompare(a.listDesc, b.listDesc))
  //     .map(x => {
  //       return { listValue: x.listValue, listDesc: x.listDesc }
  //     })
  // }

  // static async getLubeMfgsAsync(): Promise<Array<TAPIPicklistItem>> {
  //   const data = await GraphQLService.query(`
  //   query Divisions
  //   {
  //       lubeMfrs {
  //           pickListValue,
  //           lubemfr
  //       }
  //   }`)

  //   return data.lubeMfrs
  //     .sort((a, b) => this.StringCompare(a.lubemfr, b.lubemfr))
  //     .map(x => {
  //       return { pickListValue: x.pickListValue, lubemfr: x.lubemfr }
  //     })
  // }

  // static async getTestGroupsAsync(): Promise<Array<TAPITestGroup>> {
  //   const data = await GraphQLService.query(`
  //   query Divisions
  //   {
  //       testGroups {
  //           testGroupID,
  //           testGroupName
  //       }
  //   }`)

  //   return data.testGroups
  // }

  static getMinimumProfile() {
    let profileAbortController = new AbortController()

    const profilePromise = new Promise((resolve, reject) => {
      if (profileAbortController.signal.aborted) {
        reject(new Error('Query aborted by user.'))
      }

      const queryPromise = GraphQLService.query(`
query Profile
{
    user {
        firstName,
        lastName,
        loginID,
        readOnly,
        cCID,
        email,
        phone,
        cell,
        updatePassword
    },
    divisions {
        iD,
        name,
        customers {
            custID
        }
    },
    customers(minimize: true) {
        custID,
        companyID,
        custName,
        uPSSI,
        industryCode,
        dBLogo,
        routes {
            routeID,
            routeNo,
            name,
            custID
        },
        plants {
            plantID,
            plantName,
            plantNo
        }
    }
}`)
      profileAbortController = queryPromise.abortController

      queryPromise.then(data => {
        const isNotSameAsLast = (
          current: any,
          last: any,
          comparator?: (_v1: any, _v2: any) => boolean
        ) => {
          return comparator ? comparator(current, last) : current !== last
        }

        const stringCompare = (a: string, b: string) => a.localeCompare(b)

        const getPlants = (customers: TAPICustomer[]) => {
          return ([] as TAPIPlant[])
            .concat(...customers.map(x => x.plants))
            .sort((a, b) => stringCompare(a.plantName, b.plantName))
            .filter((x, index, arr) => {
              return (
                index === 0 ||
                isNotSameAsLast(x.plantName, arr[index - 1].plantName)
              )
            })
        }

        const getRoutes = (customers: TAPICustomer[]) => {
          return ([] as TAPIRoute[])
            .concat(...customers.map(x => x.routes))
            .sort((a, b) => a.routeID - b.routeID)
            .filter((x, index, arr) => {
              return (
                index === 0 ||
                isNotSameAsLast(x.routeID, arr[index - 1].routeID)
              )
            })
        }

        if (data?.customers != null) {
          const plants = getPlants(data.customers)

          resolve({
            user: data.user,
            divisions: data.divisions,
            customers: data.customers,
            plants: plants,
            routes: getRoutes(data.customers),
            error: null,
          })
        }

        resolve(null)
      })
    }) as CancellablePromise<Partial<TProfile>>

    return profilePromise
  }

  static getFullProfile(minProfile: Partial<TProfile>) {
    //     user {
    //     firstName,
    //     lastName,
    //     loginID,
    //     readOnly,
    //     cCID
    // },
    // divisions {
    //     iD,
    //     name,
    //     customers {
    //         custID
    //     }
    // },

    let profileAbortController = new AbortController()

    const profilePromise = new Promise((resolve, reject) => {
      if (profileAbortController.signal.aborted) {
        reject(new Error('Query aborted by user.'))
      }

      const queryPromise = GraphQLService.query(`
query Profile
{

  customers {
        custID,
        companyID,
        custName,
        uPSSI,
        industryCode,
        dBLogo,
        routes {
          routeID,
          routeNo,
          name,
          custID
        },
        plants {
            plantID,
            plantName,
            plantNo,
            machines {
                pointID,
                machineName,
                machType,
                machMFG,
                modelName,
                machNo,
                custEquID,
                lubricant {
                    lubeTypeID,
                    lubricantName,
                    lubeMFG
                }
            }
        }
    },
    correctiveActions: listItems(listType: 73, locationID: 1) {
        listValue,
        listDesc
    },
    machineTypes: listItems(listType: 28, locationID: 1) {
        listValue,
        listDesc
    },
    machineMfgs: listItems(listType: 27, locationID: 1) {
        listValue,
        listDesc
    },
    lubeMfrs {
        pickListValue,
        lubemfr
    },
    testGroups {
        testGroupID,
        testGroupName
    }
}`)

      profileAbortController = queryPromise.abortController

      queryPromise.then(data => {
        data = {
          ...minProfile,
          ...data,
        }

        if (window?.Worker) {
          getProfileParser()
            .parse(data)
            .then(result => {
              resolve(result)
            })
        } else {
          return ParseProfileData(data).then(result => {
            resolve(result)
          })
        }
      })
    }) as CancellablePromise<Partial<TProfile>>

    return profilePromise
  }

  static async getFiles(custIDs) {
    const token = await LoginService.getStoredAccessToken()
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/datafiles?custIDs=${custIDs.join(',')}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          ...getIsInternalUserHeader(),
        },
      }
    )

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return await response.json()
  }

  static async getJobReportFiles(custIDs) {
    const token = await LoginService.getStoredAccessToken()
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/jobreportfiles?custIDs=${custIDs.join(
        ','
      )}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          ...getIsInternalUserHeader(),
        },
      }
    )

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return await response.json()
  }

  static async getFileByPath(targetFile: string) {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/datafiles/${encodeURIComponent(
        targetFile
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getIsInternalUserHeader(),
        },
      }
    )

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    const buffer = await response.arrayBuffer()

    return {
      buffer: buffer,
      mimeType: response.headers.get('Content-Type'),
    }
  }

  static getURLForFile(custID, folder, fileName) {
    return `${
      process.env.REACT_APP_API_URL
    }/${folder}/${custID}/${encodeURIComponent(fileName)}`
  }

  static async getFile(custID, fileName) {
    const result = await this.getFileAndMimeType(custID, fileName)
    return result.buffer
  }

  static async getFileAndMimeType(custID, fileName, folder = 'datafiles') {
    const token = await LoginService.getStoredAccessToken()
    const response = await fetch(this.getURLForFile(custID, folder, fileName), {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        ...getIsInternalUserHeader(),
      },
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    const buffer = await response.arrayBuffer()

    return {
      buffer: buffer,
      mimeType: response.headers.get('Content-Type'),
    }
  }

  static async getPrefetchManifest() {
    const token = await LoginService.getStoredAccessToken()
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/prefetch-manifest`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          ...getIsInternalUserHeader(),
        },
      }
    )

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return await response.json()
  }

  static getDashboardSamplesSnapshot(variables) {
    return GraphQLService.query(
      `
query DashboardSamplesSnapshot($customerIDs: [Int], $plantIDs: [Int], $year: Int!, $month: Int!)
{
    analytics {
        samplesSnapshot(customerIDs: $customerIDs, plantIDs: $plantIDs, year: $year, month: $month) {
            plantID,
            plantName,
            samples,
            machineNormal,
            machineMarginal,
            machineCritical,
            lubeNormal,
            lubeMarginal,
            lubeCritical
        }
    }
}`,
      variables
    )
  }

  static getDashboardSamplesSnapshotDates(variables) {
    return GraphQLService.query(
      `
query DashboardSamplesSnapshotDates($customerIDs: [Int], $plantIDs: [Int])
{
    analytics {
        samplesSnapshotDates(customerIDs: $customerIDs, plantIDs: $plantIDs) {
            month,
            year
        }
    }
}`,
      variables
    )
  }

  static getDashboardRecurringExceptions(variables) {
    return GraphQLService.query(
      `
query DashboardRecurringExceptions($customerIDs: [Int], $plantIDs: [Int], $periodMonths: Int)
{
    analytics {
        recurringExceptions(customerIDs: $customerIDs, plantIDs: $plantIDs, exceptionCount: 4, periodMonths: $periodMonths) {
            batchID,
            customerID,
            plantName,
            machineNumber,
            machineName,
            custEquID,
            machType,
            lastSampleDate,
            labID,
            sampleStatus,
            tests,
            testPackages,
            lubricant,
            wearMetalCount,
            ferrousCount,
            particleCountCount,
            waterCount,
            viscosityCount,
            degradationCount,
            otherCount,
            divisionName,
            customerName,
        }
    }
}`,
      variables
    )
  }

  static getDashboardSamplesCompliance(variables) {
    return GraphQLService.query(
      `
query DashboardSamplesCompliance($customerIDs: [Int], $plantIDs: [Int], $periodMonths: Int!)
{
    analytics {
        samplesDueByMonth(customerIDs: $customerIDs, plantIDs: $plantIDs) {
            month,
            count
        },
        samplesReceivedByMonth(customerIDs: $customerIDs, plantIDs: $plantIDs, periodMonths: $periodMonths) {
            year,
            month,
            count
        }
    }
}`,
      variables
    )
  }

  static getLubricantManufacturers() {
    const promise = GraphQLService.query(
      `query lubricants {
        lubeMfrs {
            pickListValue
            lubemfr
        }
    }`
    )

    const cancellablePromise = new Promise(async (resolve, reject) => {
      try {
        const result = await promise
        return resolve(result?.lubeMfrs)
      } catch (err) {
        reject(err)
      }
    }) as CancellablePromise<TAPILubricantManufacturer[]>

    cancellablePromise.abortController = promise.abortController
    return cancellablePromise
  }

  static getLubricantTypes() {
    const promise = GraphQLService.query(
      `query lubricants {
        lubricants {
            lubeTypeID
            lubeMFG
            lubricantName
        }
    }`
    )

    const cancellablePromise = new Promise(async (resolve, reject) => {
      try {
        const result = await promise
        return resolve(result?.lubricants)
      } catch (err) {
        reject(err)
      }
    }) as CancellablePromise<TAPILubricant[]>

    cancellablePromise.abortController = promise.abortController
    return cancellablePromise
  }

  static getDashboardExceptions(variables) {
    return GraphQLService.query(
      `
query DashboardExceptions($customerIDs: [Int], $plantIDs: [Int], $periodMonths: Int!)
{
    analytics {
        exceptionsByMonth(customerIDs: $customerIDs, plantIDs: $plantIDs, periodMonths: $periodMonths) {
            year,
            month,
            machineCount,
            lubeCount
        }
    }
}
`,
      variables
    )
  }

  static getDashboardExceptionsSummary(variables) {
    return GraphQLService.query(
      `
query DashboardExceptionsSummary($customerIDs: [Int], $plantIDs: [Int])
{
    analytics {
        exceptionsSummary(customerIDs: $customerIDs, plantIDs: $plantIDs, periodMonths: 3) {
            wearMetals,
            ferrousWear,
            particleCount,
            water,
            viscosity,
            degradation,
            other
        }
    }
}
`,
      variables
    )
  }

  static getDashboardLastSamples(variables) {
    return GraphQLService.query(
      `
query DashboardLastSamples($customerIDs: [Int], $plantIDs: [Int])
{
    pagedSamples(customerIDs: $customerIDs, plantIDs: $plantIDs, page: 1, pageSize: 100, lastSamples: true) {
        items {
            sampleDate,
            machCond,
            problems,
            cGProblem {
              testID,
              problem
            },
            lubCond,
            labID,
            sampleStatus,
            batchID,
            machine {
                machNo,
                machineName,
                custEquID,
                machType,
                plant {
                    plantName,
                    customer {
                        custID
                    }
                }
                lubricant {
                    lubricantName
                }
            }
        }
    }
}`,
      variables
    )
  }

  static getOilAnalysis(variables) {
    return GraphQLService.query(
      `
query OilAnalysisSamples(
    $page: Int,
    $pageSize: Int,
    $orderList: [String],
    $orderByList: [String],
    $startDate: Date, 
    $endDate: Date,
    $sampleDate: Boolean,
    $reportDate: Boolean
    $customerIDs: [Int],
    $plantIDs: [Int],
    $machineNames: [String],
    $custEquIDs: [String],
    $routeIDs: [Int],
    $machineTypes: [String],
    $lubeTypes: [Int],
    $machineConditions: [Int],
    $lubeConditions: [Int],
    $reportTypes: [Int],
    $labNo: String,
    $serialNo: String,
    $reviewed: Boolean,
    $machineLubeConditionANDOR: String)
{
    pagedSamples(page: $page,
            pageSize: $pageSize,
            orderList: $orderList,
            orderByList: $orderByList,
            complete: true, 
            startDate: $startDate, 
            endDate: $endDate, 
            sampleDate: $sampleDate, 
            reportDate: $reportDate, 
            customerIDs: $customerIDs,
            plantIDs: $plantIDs, 
            machineNames: $machineNames, 
            custEquIDs: $custEquIDs, 
            routeIDs: $routeIDs,
            machineTypes: $machineTypes,
            lubeTypes: $lubeTypes,
            machineConditions: $machineConditions,
            lubeConditions: $lubeConditions,
            reportTypes: $reportTypes,
            labNo: $labNo,
            serialNo: $serialNo,
            reviewed: $reviewed,
            includeResults: false,
            machineLubeConditionANDOR: $machineLubeConditionANDOR) {
        count,
        items {
          problems,
                cGProblem {
              testID,
              problem
            },
            batchID,
            labID,
            lubCond,
            sampleStatus,
            machCond,
            sampleNo,
            sampleDate,
            reportDate,
            reportType,
            rptNotes,
            reviewed,
            cuCorrAct {
                cADate,
                oCDate,
                cACode,
                cANote,
                workOrderNumber,
                newOil
            },
            latestFeedback {
                feedback
            },
            machine {
                machNo,
                machineName,
                custEquID,
                machType,
                lubricant {
                    lubricantName
                },
                plant {
                    plantID,
                    plantName,
                    customer {
                        custID,
                        custName,
                        division {
                            name
                        }
                    }
                },
                route {
                    routeID,
                    routeNo,
                    name
                }
            }
        }
    }
}`,
      variables
    )
  }

  // static async getLubeByMfg(manufacturers: string[]) {
  //   const result = await GraphQLService.query(
  //     `
  //       query GetLubricantsByManufacturers($manufacturers: [String]){
  //           lubricantsByManufacturers(manufacturers: $manufacturers){
  //           lubricantName,
  //           lubeTypeID,
  //           lubeMFG,
  //           lubeNo
  //           }
  //       }
  //   `,
  //     { manufacturers }
  //   )
  //   return result?.lubricantsByManufacturers
  // }

  static getSampleStatus(variables) {
    // lube types
    // Mach types
    // test packages
    // pointID

    return GraphQLService.query(
      `
query SampleStatusSamples(
    $page: Int,
    $customerIDs: [Int],
    $pageSize: Int,
    $orderList: [String],
    $orderByList: [String],
    $startDate: Date, 
    $endDate: Date,
    $routeIDs: [Int],
    $plantIDs: [Int],
    $machineNames: [String],
    $custEquIDs: [String],
    $machineConditions: [Int],
    $lubeConditions: [Int],
    $reportTypes: [Int],
    $sampleStatusCodes:[Int],
    $machineLubeConditionANDOR: String
    )
{
    sampleStatus(page: $page,
            customerIDs: $customerIDs,
            pageSize: $pageSize,
            orderList: $orderList,
            orderByList: $orderByList,
            startDate: $startDate, 
            endDate: $endDate,
            routeIDs: $routeIDs,
            plantIDs: $plantIDs, 
            machineNames: $machineNames, 
            custEquIDs: $custEquIDs, 
            machineConditions: $machineConditions,
            lubeConditions: $lubeConditions,
            reportTypes: $reportTypes,
            sampleStatusCodes: $sampleStatusCodes,
            machineLubeConditionANDOR: $machineLubeConditionANDOR
            ) {
        count,
        items {
          problems,
                cGProblem {
              testID,
              problem
            },
            batchID,
            labID,
            lubeCondition,
            machineCondition,
            sampleDate,
            sampleStatus,
            receivedDate,
            machNo,
            custEquID,
            machName,
            machType,
            plantID,
            plantName,
            custID,
            custName,
            routeID,
            routeNo,
            routeName,
            reportType,
            testPackageList,
            holdDate,
            holdNotes,
            holdReason,
        }
    }
}`,
      variables
    )
  }

  static getConditionSummary(variables) {
    return GraphQLService.query(
      `
query ConditionSummary($customerIDs: [Int], $plantIDs: [Int], $startDate: Date, $endDate: Date)
{
    analytics {
        conditionSummary(customerIDs: $customerIDs, plantIDs: $plantIDs, startDate: $startDate, endDate: $endDate) {
            plant {
                plantID,
                plantName,
                customer {
                    custID,
                    custName,
                    division {
                        name
                    }
                }
            },
            samples,
            machineNormal,
            machineMarginal,
            machineCritical,
            machineUnknown,
            lubeNormal,
            lubeMarginal,
            lubeCritical,
            lubeUnknown
        }
    }
}`,
      variables
    )
  }

  static getChangeOfConditions(variables) {
    return GraphQLService.query(
      `
      query ChangeOfConditions($customerIDs: [Int], $plantIDs: [Int], $machineNames: [String], $startDate: Date!, $endDate: Date!, $page: Int, $pageSize: Int, $orderList: [String], $orderByList: [String], $custEquIDs: [String])
      {
        conditionChanges(customerIDs: $customerIDs, plantIDs: $plantIDs, machineNames: $machineNames, startDate: $startDate, endDate: $endDate, page: $page, pageSize: $pageSize, orderList: $orderList, orderByList: $orderByList, custEquIDs: $custEquIDs) {
          count,
          items {
            machine {
              pointID,
              machNo,
              machineName,
              plant {
                plantName,
                custID,
                customer {
                  custName,
                  custID,
                  division {
                    name
                  }
                }
              }
            },
            currentSample {
              batchID,
              problems,
                    cGProblem {
              testID,
              problem
            },,
              labID,
              sampleDate,
              machCond,
              lubCond,
              sampleStatus
            },
            previousSample {
                batchID,
                problems,
                      cGProblem {
              testID,
              problem
            },,
                labID,
                sampleDate,
                machCond,
                lubCond,
                sampleStatus
            }
          }
        }
  }`,
      variables
    )
  }

  static getSummaryReport(variables) {
    return GraphQLService.query(
      `
query SummaryReportSamples(
    $page: Int,
    $pageSize: Int,
    $orderList: [String],
    $orderByList: [String],
    $startDate: Date, 
    $endDate: Date,
    $customerIDs: [Int],
    $plantIDs: [Int],
    $routeIDs: [Int],
    $machineConditions: [Int],
    $lubeConditions: [Int],
    $machineLubeConditionANDOR: String,
    $reportTypes: [Int])
{
    pagedSamples(page: $page,
            pageSize: $pageSize,
            orderList: $orderList,
            orderByList: $orderByList,
            complete: true, 
            startDate: $startDate, 
            endDate: $endDate, 
            customerIDs: $customerIDs,
            plantIDs: $plantIDs, 
            routeIDs: $routeIDs,
            machineConditions: $machineConditions,
            lubeConditions: $lubeConditions,
            machineLubeConditionANDOR: $machineLubeConditionANDOR,
            reportTypes: $reportTypes) {
        count,
        items {
            batchID,
            labID,
            lubCond,
            machCond,
            problems,
                  cGProblem {
              testID,
              problem
            },,
            sampleNo,
            sampleDate,
            recommendations,
            reportType,
            sampleStatus,
            machine {
                machNo,
                custEquID,
                machineName,
                plant {
                    plantID,
                    plantName,
                    customer {
                        custID,
                        custName,
                        division {
                            name
                        }
                    }
                },
                route {
                    routeID,
                    routeNo,
                    name
                }
            },
            sampleTests {
                testGroup {
                    testGroupName,
                    testGroupDefs {
                        testCode {
                            testCodeName
                        }
                    }
                }
            }
        }
    }
}`,
      variables
    )
  }

  static getCommonExceptions(variables) {
    return GraphQLService.query(
      `
query CommonExceptions($customerIDs: [Int], $plantIDs: [Int], $startDate: Date!, $endDate: Date!, $orderList: [String], $orderByList: [String])
{
    analytics {
        exceptions(customerIDs: $customerIDs, plantIDs: $plantIDs, startDate: $startDate, endDate: $endDate, orderList: $orderList, orderByList: $orderByList) {
            plant {
                plantID,
                plantName,
                customer {
                    custID,
                    custName,
                    division {
                        name
                    }
                }
            },
            totalSamples,
            totalAlarmedSamples,
            totalAlarmedMachines,
            wearMetals,
            ferrousWear,
            particleCount,
            water,
            viscosity,
            degradation,
            other
        }
    }
}`,
      variables
    )
  }

  static getDelinquentSamples(variables) {
    return GraphQLService.query(
      `
      query DelinquentSamples($customerIDs: [Int], $plantIDs: [Int], $routeIDs: [Int], $days: Int!, $startDate: Date, $endDate: Date, $page: Int, $pageSize: Int, $orderList: [String], $orderByList: [String])
      {
          analytics {
              delinquentSamples(customerIDs: $customerIDs, plantIDs: $plantIDs, routeIDs: $routeIDs, days: $days, startDate: $startDate, endDate: $endDate, page: $page, pageSize: $pageSize, orderList: $orderList, orderByList: $orderByList) {
                  count,
                  items {
                    machine {
                      pointID,
                      machNo,
                      custEquID,
                      machineName,
                      plant {
                          plantName,
                          customer {
                              custName,
                              division {
                                  name
                              }
                          }
                      },
                      route {
                          routeID,
                          routeNo,
                          name
                      }
                    },
                    lastSampleDate,
                    lastSampleLabID,
                    lastSampleStatus,
                    daysSinceLastSample,
                  }
              }
          }
      }`,
      variables
    )
  }

  static getScheduleCompliance(variables) {
    return GraphQLService.query(
      `
query ScheduleCompliance(
    $page: Int,
    $pageSize: Int,
    $orderList: [String],
    $orderByList: [String],
    $customerIDs: [Int],
    $plantIDs: [Int],
    $machineNames: [String],
    $routeIDs: [Int],
    $custEquIDs: [String],
    $testGroupIDs: [Int])
{
    analytics {
        pagedScheduleCompliance(page: $page,
                pageSize: $pageSize,
                orderList: $orderList,
                orderByList: $orderByList,
                customerIDs: $customerIDs,
                plantIDs: $plantIDs, 
                custEquIDs: $custEquIDs,
                machineNames: $machineNames, 
                routeIDs: $routeIDs,
                testGroupIDs: $testGroupIDs) {
            count,
            items {
                latestLabID,
                machine {
                    machNo,
                    custEquID,
                    machineName,
                    plant {
                        plantID,
                        plantName,
                        customer {
                            custID,
                            custName,
                            division {
                                name
                            }
                        }
                    },
                    route {
                        routeID,
                        routeNo,
                        name
                    }
                },
                testGroup {
                    testGroupID,
                    testGroupName,
                    testGroupDefs {
                        testGroupID,
                        testCode {
                            testCodeName
                        }
                    }
                },
                counts {
                    month,
                    year,
                    scheduled,
                    tested,
                    machCond,
                    lubCond
                }
            }
        }
    }
}`,
      variables
    )
  }

  static getRecurringExceptions(variables) {
    return GraphQLService.query(
      `
query RecurringExceptions($customerIDs: [Int], $plantIDs: [Int], $exceptionCount: Int!, $startDate: Date, $endDate: Date, $lubeTypes: [Int], $machineTypes: [String])
{
    analytics {
        recurringExceptions(customerIDs: $customerIDs, plantIDs: $plantIDs, exceptionCount: $exceptionCount, startDate: $startDate, endDate: $endDate, lubeTypes: $lubeTypes, machineTypes: $machineTypes) {
            batchID,
            customerID,
            divisionName,
            customerName,
            plantName,
            machineNumber,
            custEquID,
            machineName,
            lastSampleDate,
            labID,
            sampleStatus,
            tests,
            testPackages,
            lubricant,
            wearMetalCount,
            ferrousCount,
            particleCountCount,
            waterCount,
            viscosityCount,
            degradationCount,
            otherCount
        }
    }
}`,
      variables
    )
  }

  static getLastSamples(variables) {
    return GraphQLService.query(
      `
query LastSamples(
    $page: Int,
    $pageSize: Int,
    $orderList: [String],
    $orderByList: [String],
    $startDate: Date, 
    $endDate: Date,
    $customerIDs: [Int],
    $plantIDs: [Int],
    $machineNames: [String],
    $custEquIDs: [String],
    $routeIDs: [Int],
    $machineTypes: [String],
    $lubeTypes: [Int],
    $machineConditions: [Int],
    $lubeConditions: [Int],
    $machineLubeConditionANDOR: String,
    $reviewed: Boolean)
{
    pagedSamples(page: $page,
            pageSize: $pageSize,
            orderList: $orderList,
            orderByList: $orderByList,
            complete: true, 
            startDate: $startDate, 
            endDate: $endDate, 
            customerIDs: $customerIDs,
            plantIDs: $plantIDs, 
            machineNames: $machineNames, 
            custEquIDs: $custEquIDs
            routeIDs: $routeIDs,
            machineTypes: $machineTypes,
            lubeTypes: $lubeTypes,
            machineConditions: $machineConditions,
            lubeConditions: $lubeConditions,
            machineLubeConditionANDOR: $machineLubeConditionANDOR,
            reviewed: $reviewed,
            lastSamples: true) {
        count,
        items {
            batchID,
            labID,
            sampleStatus,
            lubCond,
            machCond,
            sampleNo,
            sampleDate,
            reviewed,
            custReviewDte,
            problems,
                  cGProblem {
              testID,
              problem
            },,
            machine {
                machNo,
                custEquID,
                machineName,
                machType,
                lubricant {
                    lubricantName
                },
                plant {
                    plantID,
                    plantName,
                    customer {
                        custID,
                        custName,
                        division {
                            name
                        }
                    }
                },
                route {
                    routeID,
                    routeNo,
                    name
                }
            }
        }
    }
}`,
      variables
    )
  }

  static getScheduledSamplesDue(variables) {
    return GraphQLService.query(
      `
query ScheduledSamplesDue(
    $page: Int,
    $pageSize: Int,
    $orderList: [String],
    $orderByList: [String],
    $customerIDs: [Int],
    $plantIDs: [Int],
    $machineNames: [String],
    $routeIDs: [Int],
    $custEquIDs: [String],
    $testGroupIDs: [Int],
    $startDate: Date,
    $endDate: Date,
    $hideCompleted: Boolean)
{
    analytics {
        pagedScheduleCompliance(page: $page,
                pageSize: $pageSize,
                orderList: $orderList,
                orderByList: $orderByList,
                customerIDs: $customerIDs,
                plantIDs: $plantIDs, 
                machineNames: $machineNames, 
                routeIDs: $routeIDs,
                custEquIDs: $custEquIDs,
                testGroupIDs: $testGroupIDs,
                startDate: $startDate,
                endDate: $endDate,
                hideCompleted: $hideCompleted) {
            count,
            items {
                latestLabID,
                machine {
                    machNo,
                    machineName,
                    custEquID,
                    plant {
                        plantID,
                        plantName,
                        customer {
                            custID,
                            custName,
                            division {
                                name
                            }
                        }
                    },
                    route {
                        routeID,
                        routeNo,
                        name
                    }
                },
                testGroup {
                    testGroupID,
                    testGroupName,
                    testGroupDefs {
                        testGroupID,
                        testCode {
                            testCodeName
                        }
                    }
                },
                counts {
                    month,
                    year,
                    scheduled,
                    tested,
                    machCond,
                    lubCond
                }
            }
        }
    }
}`,
      variables
    )
  }

  static getDARTSystem(variables) {
    return GraphQLService.query(
      `
query DARTSystem(
    $page: Int,
    $pageSize: Int,
    $orderList: [String],
    $orderByList: [String],
    $startDate: Date,
    $endDate: Date,
    $sampleDate: Boolean,
    $reportDate: Boolean
    $customerIDs: [Int],
    $plantIDs: [Int],
    $machineNames: [String],
    $routeIDs: [Int],
    $machineTypes: [String],
    $lubeTypes: [Int],
    $machineConditions: [Int],
    $lubeConditions: [Int],
    $machineLubeConditionANDOR: String,
    $reviewed: Boolean)
{
    pagedSamples(
        page: $page,
        pageSize: $pageSize,
        orderList: $orderList,
        orderByList: $orderByList,
        complete: true,
        startDate: $startDate,
        endDate: $endDate,
        sampleDate: $sampleDate,
        reportDate: $reportDate,
        customerIDs: $customerIDs,
        plantIDs: $plantIDs,
        machineNames: $machineNames,
        routeIDs: $routeIDs,
        machineTypes: $machineTypes,
        lubeTypes: $lubeTypes,
        machineConditions: $machineConditions,
        lubeConditions: $lubeConditions,
        machineLubeConditionANDOR: $machineLubeConditionANDOR,
        reviewed: $reviewed,
        ) {
        count,
        items {
            batchID,
            labID,
            problems,
                  cGProblem {
              testID,
              problem
            },,
            sampleStatus,
            seqNo,
            lubCond,
            machCond,
            sampleDate,
            reviewed,
            custReviewDte,
                latestFeedback {
                feedback
            },
            pointID,
            machine {
                machNo,
                machineName,
                custEquID,
                machType,
                lubricant {
                    lubricantName,
                    fluidType,
                    iRMethod
                },
                plant {
                    plantID,
                    plantNo,
                    plantName,
                    customer {
                        custID,
                        custName,
                        division {
                            name
                        }
                    }
                },
                route {
                    routeID,
                    routeNo,
                        name
                }
            }
        }
    }
}`,
      variables
    )
  }

  static getDARTData(variables) {
    return GraphQLService.query(
      `
query DARTData($labID: Int!, $isRef: Boolean)
{
    sample(labID: $labID, isRef: $isRef) {
        labID,
        sampleStatus,
        seqNo,
        batchID,
        pointID,
        lubCond,
        machCond,
        sampleDate,
        sampleNo,
        receivedDate,
        reportDate,
        reviewed,
        custReviewDte,
        analyst,
        analystIsiUser {
            initials,
            name
        },
        recommendations,
        problems,
              cGProblem {
              testID,
              problem
            },,
        rptNotes,
        oCDate,
        oHDate,
        oilChange,
        lubricant {
            lubricantName,
            fluidType,
            iRMethod
        }
        cuCorrAct {
            cADate,
            oCDate,
            newOil,
            cANote,
            workOrderNumber
        }
        latestFeedback {
            feedback
        },
        sampleTests {
            labID,
            testGroup {
                testGroupID,
                testGroupName
            }
        },
        machine {
            machNo,
            machineName,
            machType,
            machMFG,
            modelName,
            sumpCap,
            custEquID,
            lubricant {
                lubricantName,
                refLabID,
                fluidType,
                iRMethod
            },
            plant {
                plantID,
                plantNo,
                plantName,
                customer {
                    custID,
                    custName,
                    division {
                        name
                    }
                },
                plantContact {
                    address1,
                    address2,
                    address3,
                    city,
                    state,
                    zip
                }
            },
            route {
                routeID,
                routeNo,
                name
            }
        },
        isiCustRefOil {
            refLabID
        },
        isiAf {
            labID
        },
        isiColor {
            result,
            resultFlg
        },
        isiDem {
            oil,
            water,
            emulsion,
            demTime,
            demFlg
        },
        isiFd {
            result,
            resultFlg
        },
        isiFlashpoint {
            result,
            resultFlg
        },
        isiFoam {
            s1Time,
            foamFlg
        },
        isiGlycol {
            result,
            resultFlg
        },
        isiIr24KtMethod {
            additive,
            hydrocarbon,
            methyl,
            methylene
        },
        isiIrJoapMethod {
            oxidProd,
            antiWear,
            glycol,
            soot,
            nitraProd,
            gasDil,
            product,
            polyolEster,
            phosphateEsterOH,
            phosphateEsterOx
        },
        isiIrTurbineMethod {
            acidUnsatEst,
            aCIDOxid,
            amines,
            aromatics,
            olefins,
            oxidationEster,
            phenolicOH
        },
        isiKf {
            result,
            resultFlg
        },
        isiMpc {
            mPC,
            l,
            a,
            b,
            mPCflg
        },
        isiPc {
            g2,
            g5,
            g15,
            g50,
            g100,
            iSO1,
            iSO2,
            iSO3,
            pCFlg
        },
        isiPcl {
            g4,
            g6,
            g14,
            g50,
            g68,
            iSO1,
            iSO2,
            iSO3,
            pCLFlg
        },
        isiPh {
            result,
            resultFlg
        },
        isiPhd {
            result,
            resultFlg
        },
        isiRa {
            result,
            resultFlg
        },
        isiRpvot {
            rPVOT,
            rPVOTFlg
        },
        isiRuler {
            amine,
            phenolic,
            zddp,
            rulerFlg
        },
        isiRust {
            result,
            resultFlg
        },
        isiSpc {
            iron,
            copper,
            lead,
            aluminum,
            tin,
            nickel,
            chromium,
            titanium,
            vanadium,
            silver,
            silicon,
            boron,
            sodium,
            potassium,
            phosphorus,
            zinc,
            barium,
            molybdenum,
            calcium,
            magnesium,
            lithium,
            fEflg,
            cUflg,
            pBflg,
            aLflg,
            sNflg,
            nIflg,
            cRflg,
            tIflg,
            vflg,
            aGflg,
            sIflg,
            bflg,
            nAflg,
            kflg,
            pflg,
            zNflg,
            bAflg,
            mOflg,
            cAflg,
            mGflg,
            lIflg
        },
        isiTan {
            result,
            resultFlg
        },
        isiResistivity {
          result,
          resultFlg
        },
        isiTbn {
            result,
            resultFlg
        },
        isiUc {
            uC,
            uCFlg
        },
        isiV40 {
            results,
            visFlg
        },
        isiV100 {
            results,
            visFlg
        },
        isiWp {
            wPC,
            wPFlg
        }
    }
}`,
      variables
    )
  }

  static getDARTSamples(variables) {
    return GraphQLService.query(
      `
query DARTSamples(
    $pointID: Int!, 
    $startDate: Date!, 
    $endDate: Date!,
    $pageSize: Int!)
{
    pagedSamples(
        pointID: $pointID, 
        startDate: $startDate,
        endDate: $endDate,
        page: 1, 
        pageSize: $pageSize) {
        items {
            labID,
            sampleStatus,
            seqNo,
            pointID,
            lubCond,
            machCond,
            sampleDate,
            sampleNo,
            receivedDate,
            reportDate,
            reviewed,
            custReviewDte,
            analyst,
            recommendations,
            problems,
                  cGProblem {
              testID,
              problem
            },,
            rptNotes,
            timeSinceOC,
            timeSinceOH,
            oilChange,
            machine {
                machNo,
                machineName,
                custEquID,
                machType,
                machMFG,
                modelName,
                sumpCap,
                lubricant {
                    lubricantName,
                    refLabID
                },
                plant {
                    plantID,
                    plantNo,
                    plantName,
                    customer {
                        custID,
                        custName,
                        division {
                            name
                        }
                    }
                }
            },
            lubricant {
                lubricantName
            }
            cuCorrAct {
                cADate,
                oCDate,
                newOil,
                cANote,
                workOrderNumber
            }
            sampleTests {
                labID,
                testGroup {
                    testGroupID,
                    testGroupName
                }
            },
            isiAf {
                labID
            },
            isiColor {
                result,
                resultFlg
            },
            isiDem {
                oil,
                water,
                emulsion,
                demTime,
                demFlg
            },
            isiFd {
                result,
                resultFlg
            },
            isiFlashpoint {
                result,
                resultFlg
            },
            isiFoam {
                s1Time,
                foamFlg
            },
            isiGlycol {
                result,
                resultFlg
            },
            isiIr24KtMethod {
                additive,
                hydrocarbon,
                methyl,
                methylene
            },
            isiIrJoapMethod {
                oxidProd,
                antiWear,
                glycol,
                soot,
                nitraProd,
                gasDil,
                product,
                polyolEster,
                phosphateEsterOH,
                phosphateEsterOx
            },
            isiIrTurbineMethod {
                acidUnsatEst,
                aCIDOxid,
                amines,
                aromatics,
                olefins,
                oxidationEster,
                phenolicOH
            },
            isiKf {
                result,
                resultFlg
            },
            isiMpc {
                mPC,
                l,
                a,
                b,
                mPCflg
            },
            isiPc {
                g2,
                g5,
                g15,
                g50,
                g100,
                iSO1,
                iSO2,
                iSO3,
                pCFlg
            },
            isiPcl {
                g4,
                g6,
                g14,
                g50,
                g68,
                iSO1,
                iSO2,
                iSO3,
                pCLFlg
            },
            isiPh {
                result,
                resultFlg
            },
            isiPhd {
                result,
                resultFlg
            },
            isiRa {
                result,
                resultFlg
            },
            isiRpvot {
                rPVOT,
                rPVOTFlg
            },
            isiRuler {
                amine,
                phenolic,
                zddp,
                rulerFlg
            },
            isiRust {
                result,
                resultFlg
            },
            isiSpc {
                iron,
                copper,
                lead,
                aluminum,
                tin,
                nickel,
                chromium,
                titanium,
                vanadium,
                silver,
                silicon,
                boron,
                sodium,
                potassium,
                phosphorus,
                zinc,
                barium,
                molybdenum,
                calcium,
                magnesium,
                lithium,
                fEflg,
                cUflg,
                pBflg,
                aLflg,
                sNflg,
                nIflg,
                cRflg,
                tIflg,
                vflg,
                aGflg,
                sIflg,
                bflg,
                nAflg,
                kflg,
                pflg,
                zNflg,
                bAflg,
                mOflg,
                cAflg,
                mGflg,
                lIflg
            },
            isiTan {
                result,
                resultFlg
            },
            isiResistivity {
              result,
              resultFlg
            },
            isiTbn {
                result,
                resultFlg
            },
            isiUc {
                uC,
                uCFlg
            },
            isiV40 {
                results,
                visFlg
            },
            isiV100 {
                results,
                visFlg
            },
            isiWp {
                wPC,
                wPFlg
            }
        }
    }
}`,
      variables
    )
  }

  static getDARTHistory(variables, test, field) {
    return GraphQLService.query(
      `
query DARTSamples(
    $pointID: Int!, 
    $startDate: Date!, 
    $endDate: Date!,
    $pageSize: Int!)
{
    pagedSamples(
        pointID: $pointID, 
        startDate: $startDate,
        endDate: $endDate,
        page: 1, 
        pageSize: $pageSize) {
        items {
            sampleDate,
            ${test} {
                ${field}
            },
            cuCorrAct {
              cADate,
              cANote
            }
        }
    }
}`,
      variables
    )
  }

  static getMachineTestData(variables) {
    return GraphQLService.query(
      `
query getMachineTestData($pointIDs: [Int]!, $startDate: Date!, $endDate: Date!)
{
    machineTestData(pointIDs: $pointIDs, startDate: $startDate, endDate: $endDate) {
        keys,
        values {
            values
        }
    }
}`,
      variables
    )
  }

  static getMachine(pointID) {
    return GraphQLService.query(
      `
query getMachines($pointID: Int!)
{
    machine(pointID: $pointID) {
        pointID,
        machNo,
        machineName,
        machType,
        machMFG,
        modelName,
        sumpCap,
        lubeTypeID,
        custEquID,
        lubricant {
            lubricantName
        },
        plant {
            plantID,
            plantName,
            customer {
                custID,
                custName,
                division {
                    name
                }
            }
        },
        route {
            routeID,
            routeNo,
            name
        }
    }
}`,
      { pointID }
    )
  }

  static getMachines(variables) {
    return GraphQLService.query(
      `
query getMachines(
    $page: Int,
    $pageSize: Int,
    $orderList: [String],
    $orderByList: [String],
    $customerIDs: [Int],
    $plantIDs: [Int],
    $routeIDs: [Int],
    $machineNumbers: [Int],
    $machineNames: [String],
    $custEquIDs: [String],
    $machineTypes: [String],
    $lubeTypeIDs: [Int],
    $machineMfgs: [String],
    $machineModels: [String])
{
    pagedMachines(page: $page,
            pageSize: $pageSize,
            orderList: $orderList,
            orderByList: $orderByList,
            customerIDs: $customerIDs,
            plantIDs: $plantIDs, 
            routeIDs: $routeIDs,
            custEquIDs:$custEquIDs,
            machineNumbers: $machineNumbers,
            machineNames: $machineNames,
            machineTypes: $machineTypes,
            lubeTypeIDs: $lubeTypeIDs,
            machineMfgs: $machineMfgs,
            machineModels: $machineModels) {
        count,
        items {
            pointID,
            machNo,
            custEquID,
            machineName,
            machType,
            machMFG,
            modelName,
            sumpCap,
            lubricant {
                lubricantName
            },
            plant {
                plantID,
                plantName,
                customer {
                    custID,
                    custName,
                    division {
                        name
                    }
                }
            },
            route {
                routeID,
                routeNo,
                name
            }
        }
    }
}`,
      variables
    )
  }

  static getMachinesForLabels(variables) {
    return GraphQLService.query(
      `
query getMachines(
    $page: Int,
    $pageSize: Int,
    $orderList: [String],
    $orderByList: [String],
    $customerIDs: [Int],
    $plantIDs: [Int],
    $routeIDs: [Int],
    $machineNumbers: [Int],
    $machineNames: [String],
    $custEquIDs: [String],
    $scheduledMonth: Int)
{
    pagedMachines(page: $page,
            pageSize: $pageSize,
            orderList: $orderList,
            orderByList: $orderByList,
            customerIDs: $customerIDs,
            plantIDs: $plantIDs, 
            routeIDs: $routeIDs,
            machineNumbers: $machineNumbers,
            machineNames: $machineNames,
            custEquIDs: $custEquIDs,
            scheduledMonth: $scheduledMonth) {
        count,
        items {
            pointID,
            machNo,
            machineName,
            custEquID,
            lubricant {
                lubricantName,
                vis40,
                fluidType
            },
            plant {
                plantID,
                plantNo,
                plantName,
                customer {
                    custID,
                    custName,
                    division {
                        name
                    }
                }
            },
            route {
                routeID,
                routeNo,
                name
            },
            machSchedules {
                testGroup {
                    testGroupID,
                    testGroupName,
                    testGroupDefs {
                        testGroupID,
                        testCode {
                            testCodeName
                        }
                    }
                }
            }
        }
    }
}`,
      variables
    )
  }

  static getMachSchedules(variables) {
    return GraphQLService.query(
      `
query MachSchedules(
    $page: Int,
    $pageSize: Int,
    $orderList: [String],
    $orderByList: [String],
    $customerIDs: [Int],
    $plantIDs: [Int],
    $machineNames: [String],
    $routeIDs: [Int],
    $custEquIDs: [String],
    $testGroupIDs: [Int])
{
    pagedMachSchedules(page: $page,
            pageSize: $pageSize,
            orderList: $orderList,
            orderByList: $orderByList,
            customerIDs: $customerIDs,
            plantIDs: $plantIDs, 
            machineNames: $machineNames, 
            routeIDs: $routeIDs,
            custEquIDs: $custEquIDs,
            testGroupIDs: $testGroupIDs) {
        count,
        items {
            mSID,
            allMonths,
            jan,
            feb,
            mar,
            apr,
            may,
            jun,
            jul,
            aug,
            sep,
            oct,
            nov,
            dec,
            machine {
                machNo,
                machineName,
                machType,
                custEquID,
                lubricant {
                    lubricantName
                },
                plant {
                    plantID,
                    plantName,
                    customer {
                        custID,
                        custName,
                        division {
                            name
                        }
                    }
                },
                route {
                    routeID,
                    routeNo,
                    name
                }
            },
            testGroup {
                testGroupID,
                testGroupName,
                testGroupDefs {
                    testCode {
                        testID,
                        testCodeName
                    }
                }
            }
        }
    }
}`,
      variables
    )
  }

  // static async getNextRouteID() {
  //   const result = await GraphQLService.query(`
  //       query LastRoute {
  //           lastRoute {
  //               routeID,
  //               routeNo
  //           }
  //       }
  //   `)
  //   return result.lastRoute.routeID + 1
  // }

  static getRoute(routeID) {
    return GraphQLService.query(
      `
query Route($routeID: Int!)
{
    route(routeID: $routeID) {
        routeID,
        routeNo,
        name,
        customer {
            custID,
            custName,
            division {
                name
            }
        }
    }
}
`,
      { routeID: routeID }
    )
  }

  static getRoutes(variables) {
    return GraphQLService.query(
      `
query Routes($customerIDs: [Int], $page: Int, $pageSize: Int, $orderList: [String], $orderByList: [String])
{
    routes(customerIDs: $customerIDs, page: $page, pageSize: $pageSize, orderList: $orderList, orderByList: $orderByList) {
        count,
        items {
          routeID,
          routeNo,
          name,
          customer {
              custID,
              custName,
              division {
                  name
              }
          }
        }
    }
}
`,
      variables
    )
  }

  static getProgramSnapshot(
    customerIDs,
    fiscalYearStartMonth,
    exceptionCount,
    startDate,
    endDate
  ) {
    return GraphQLService.query(
      `
query ProgramSnapshot($customerIDs: [Int], $exceptionCount: Int!, $fiscalYearStartMonth: Int!, $startDate: Date!, $endDate: Date!)
{
    analytics {
        sampleVolume(customerIDs: $customerIDs, fiscalYearStartMonth: $fiscalYearStartMonth, startDate: $startDate, endDate: $endDate) {
            plantID,
            plantName,
            count,
            dateRangeCount,
            years {
                year,
                count
            }
        },
        sampleExceptionVolume(customerIDs: $customerIDs, fiscalYearStartMonth: $fiscalYearStartMonth, startDate: $startDate, endDate: $endDate) {
            plantID,
            plantName,
            count,
            years {
                year,
                count,
                exceptionPercent
            }
        },
        sampleRecurringExceptionVolume(customerIDs: $customerIDs, exceptionCount: $exceptionCount, fiscalYearStartMonth: $fiscalYearStartMonth, startDate: $startDate, endDate: $endDate) {
            plantID,
            plantName,
            count,
            years {
                year,
                count,
                exceptionPercent
            }
        },
        customerSampleVolumeSummary(customerIDs: $customerIDs, exceptionCount: $exceptionCount, fiscalYearStartMonth: $fiscalYearStartMonth, startDate: $startDate, endDate: $endDate) {
            custID,
            custName,
            plants {
                plantID,
                plantName,
                samplesDue,
                samplesReceived,
                alarmedSamples,
                percentCompliance,
                ferrousWear,
                wearMetals,
                particleCount,
                water,
                viscosity,
                degradation,
                other
            }
        }
    }
}`,
      {
        customerIDs: customerIDs,
        fiscalYearStartMonth: fiscalYearStartMonth,
        exceptionCount: exceptionCount,
        startDate: startDate,
        endDate: endDate,
      }
    )
  }

  // Mutations
  static async createAnalystFeedback(variables) {
    return await GraphQLService.mutate(
      `
mutation AnalystFeedback($labID: Int!, $custID: Int!, $plantNo: Int, $machNo: Int, $firstName: String!, $lastName: String!, $email: String!, $subject: String!, $feedback: String!)
{
    createAnalystFeedback(labID: $labID, custID: $custID, plantNo: $plantNo, machNo: $machNo, firstName: $firstName, lastName: $lastName, email: $email, subject: $subject, feedback: $feedback)
}`,
      variables
    )
  }

  static async createFeedback(variables) {
    return await GraphQLService.mutate(
      `
mutation Feedback($labID: Int!, $custID: Int!, $plantNo: Int, $machNo: Int, $company: String!, $firstName: String!, $lastName: String!, $email: String!, $feedback: String!)
{
    createFeedback(labID: $labID, custID: $custID, plantNo: $plantNo, machNo: $machNo, company: $company, firstName: $firstName, lastName: $lastName, email: $email, feedback: $feedback)
    {
        fBID
    }
}`,
      variables
    )
  }

  static async markSamplesReviewed(variables) {
    return await GraphQLService.mutate(
      `
mutation MarkSamplesReviewed($labIDs: [Int]!)
{
    markReviewed(labIDs: $labIDs)
}`,
      variables
    )
  }

  static async markSamplesUnreviewed(variables) {
    return await GraphQLService.mutate(
      `
mutation MarkSamplesUnreviewed($labIDs: [Int]!)
{
    markUnreviewed(labIDs: $labIDs)
}`,
      variables
    )
  }

  static async saveCorrectiveAction(variables) {
    return await GraphQLService.mutate(
      `
mutation SaveCorrectiveAction($labID: Int!, $cACode: Int!, $oCDate: Date, $newOil: String, $cANote: String, $workOrderNumber: String)
{
    saveCuCorrAct(labID: $labID, cACode: $cACode, oCDate: $oCDate, newOil: $newOil, cANote: $cANote, workOrderNumber: $workOrderNumber)
    {
        labID
    }
}`,
      variables
    )
  }

  static async deleteCorrectiveAction(variables) {
    return await GraphQLService.mutate(
      `
mutation DeleteCorrectiveAction($labID: Int!)
{
    deleteCuCorrAct(labID: $labID)
    {
        labID
    }
}`,
      variables
    )
  }

  static async saveMachSchedules(variables) {
    return await GraphQLService.mutate(
      `
mutation SaveMachSchedules($machSchedulesList: MachScheduleListInputGraphType)
{
    saveMachSchedules(machSchedulesList: $machSchedulesList)
    {
        mSID
    }
}`,
      variables
    )
  }

  static async deleteMachSchedules(variables) {
    return await GraphQLService.mutate(
      `
mutation DeleteMachSchedules($mSIDs: [Int]!)
{
    deleteMachSchedules(mSIDs: $mSIDs)
    {
        mSID
    }
}`,
      variables
    )
  }

  static async saveRoute(variables) {
    return await GraphQLService.mutate(
      `
mutation SaveRoute($route: RouteInputGraphType)
{
    saveRoute(route: $route)
    {
        routeID
    }
}
`,
      variables
    )
  }

  static async deleteRoute(routeID) {
    return await GraphQLService.mutate(
      `
mutation DeleteRoute($routeID: Int!)
{
    deleteRoute(routeID: $routeID)
    {
        routeID
    }
}
`,
      { routeID: routeID }
    )
  }

  static async createEquipment(variables) {
    return await GraphQLService.mutate(
      `
mutation CreateEquipment(
    $custID: Int!, 
    $customerName: String!, 
    $plantID: Int!, 
    $routeName: String!,
    $contact: String!, 
    $email: String!, 
    $machineName: String!, 
    $custEquID: String!,
    $machineType: String!,
    $machineTypeDesc: String,
    $machineManufacturer: String!, 
    $machineManufacturerDesc: String, 
    $modelNumber: String!, 
    $lubeManufacturer: String,
    $lubeManufacturerDesc: String, 
    $lubeNameAndGrade: String, 
    $sampleMonths: [String], 
    $notes: String,
    $testGroupName: String
    )
{
    createEquipment(
        custID: $custID, 
        customerName: $customerName, 
        plantID: $plantID, 
        routeName: $routeName,
        contact: $contact, 
        email: $email, 
        machineName: $machineName, 
        custEquID: $custEquID, 
        machineType: $machineType, 
        machineTypeDesc: $machineTypeDesc,
        machineManufacturer: $machineManufacturer, 
        machineManufacturerDesc: $machineManufacturerDesc, 
        modelNumber: $modelNumber, 
        lubeManufacturer: $lubeManufacturer,
        lubeManufacturerDesc: $lubeManufacturerDesc, 
        lubeNameAndGrade: $lubeNameAndGrade, 
        sampleMonths: $sampleMonths, 
        notes: $notes,
        testGroupName: $testGroupName
        )
}
`,
      variables
    )
  }

  static async saveMachine(variables) {
    return await GraphQLService.mutate(
      `
mutation SaveMachine (
    $pointID: Int!, 
    $plantID: Int!,
    $routeID: Int,
    $machineName: String!,
    $custEquID : String,
    $machType: String!,
    $machMFG: String!,
    $modelName: String,
    $lubeTypeID: Int!,
    $sumpCap: Decimal)
{
    saveMachine(
        pointID: $pointID,
        plantID: $plantID,
        routeID: $routeID,
        machineName: $machineName,
        custEquID: $custEquID,
        machType: $machType,
        machMFG: $machMFG,
        modelName: $modelName,
        lubeTypeID: $lubeTypeID,
        sumpCap: $sumpCap)
    {
        pointID
    }
}
`,
      variables
    )
  }

  static async orderSupplies(variables) {
    return await GraphQLService.mutate(
      `
mutation OrderSupplies ($order: SupplyOrderInputGraphType)
{
    orderSupplies(order: $order)
}
`,
      variables
    )
  }

  static getPlantShippingAddresses(
    plantIDs: number[]
  ): CancellablePromise<{ plantContactsByIDs: TAPIPlantContact[] }> {
    return GraphQLService.query(
      `
       query GetPlantContactsByIDs ($plantIDs: [Int])
       {
            plantContactsByIDs(plantIDs: $plantIDs)
            {
                plantID,
                address1,
                address2,
                address3,
                city,
                state,
                email,
                zip
            }
        }
        `,
      { plantIDs }
    )
  }

  static getPcLimits(variables) {
    return GraphQLService.query(
      `
      query PCLimits($labID: Int!) {
        limitsForPc(labID: $labID) {
          iSO1
          iSO2
          iSO3
        }
      }`,
      variables
    )
  }

  static getSampleIssues(
    customerIDs?: number[],
    plantIDs?: number[]
  ): CancellablePromise<TSampleIssues[]> {
    if (!customerIDs.length && !plantIDs.length) return

    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/SampleIssues?`
    )

    let params = customerIDs.map(id => ['customerIDs', id.toString()])
    params = params.concat(plantIDs.map(id => ['plantIDs', id.toString()]))
    url.search = new URLSearchParams(params).toString()

    return APIService.apiGet<TSampleIssues[]>(url)
  }

  static getFinancialAlerts(
    query: TFinancialAlertsQuery
  ): CancellablePromise<TFinancialAlert[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/FinancialAlerts` +
        serialize(query)
    )

    return APIService.apiGet<TFinancialAlert[]>(url)
  }

  static getNoSalesOrderSamples(
    query: TNoSalesOrderQuery
  ): CancellablePromise<TNoSalesOrderSample[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/NoSalesOrderSamples` +
        serialize(query)
    )

    return APIService.apiGet<TNoSalesOrderSample[]>(url)
  }

  static getBillingCustomer(): CancellablePromise<TBillingCustomer[]> {
    const url = new URL(
      `${process.env.REACT_APP_API_URL}/BillingCustomer`,
      window.location.origin
    )

    return APIService.apiGet<TBillingCustomer[]>(url)
  }

  static getBillingCustomerPromise() {
    let profileAbortController = new AbortController()

    const profilePromise = new Promise((resolve, reject) => {
      if (profileAbortController.signal.aborted) {
        reject(new Error('Query aborted by user.'))
      }

      APIService.getBillingCustomer()
        .then(result => {
          resolve({ billingCustomers: result })
        })
        .catch(err => {
          reject(err)
        })
    }) as CancellablePromise<Partial<TProfile>>

    return profilePromise
  }

  static getSupplyImage(imageUrl: string): CancellablePromise<Blob> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1${process.env.REACT_APP_PRODUCT_IMAGES_ENDPOINT}?fileName=${imageUrl}`
    )

    return APIService.apiGetBlob(url)
  }

  static getSupplies(query: SupplyQuery): CancellablePromise<Product[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1${process.env.REACT_APP_PRODUCTS_ENDPOINT}` +
        serialize(query)
    )

    return APIService.apiGet<Product[]>(url)
  }

  static getTransFormerKitAvailability(
    query: TransformerKitQuery
  ): CancellablePromise<boolean> {
    if (query.isEmpty()) return

    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/ShowTransformerKit` +
        serialize(query)
    )

    return APIService.apiGet<boolean>(url)
  }

  static getCustomerShipmentInformation(
    query: Partial<TShipmentInformationQuery>
  ): CancellablePromise<TPagedShipmentInformation> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/ShipmentInformation` +
        serialize(query)
    )

    return APIService.apiGet<TPagedShipmentInformation>(url)
  }

  // static async getReceiptPdfFile(
  //   query: Partial<TReceiptQuery>
  // ): Promise<TFileDownloadResponse> {
  //   const url = new URL(
  //     `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Receipt`
  //   )

  //   url.search = new URLSearchParams(query).toString()

  //   const token = await LoginService.getStoredAccessToken()
  //   const response = await fetch(url.toString(), {
  //     method: 'GET',
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       Accept: 'application/pdf',
  //     },
  //   })

  //   return {
  //     buffer: await (await response.blob())?.arrayBuffer(),
  //     statusCode: response.status,
  //   }
  // }

  // static async getPdfReceiptAndMimeType(invoiceNumber) {
  //   const token = await LoginService.getStoredAccessToken()
  //   const response = await fetch(`${
  //     process.env.REACT_APP_API_URL
  //   }/receipts/${invoiceNumber}`, {
  //     method: 'GET',
  //     headers: {
  //       Authorization: 'Bearer ' + token,
  //     },
  //   })

  //   if (!response.ok) {
  //     throw new Error(`${response.statusText} ${response.status}`)
  //   }

  //   const buffer = await response.arrayBuffer()

  //   return {
  //     buffer: buffer,
  //     mimeType: response.headers.get('Content-Type'),
  //   }
  // }

  private static apiGetBlob(url: URL | string): CancellablePromise<Blob> {
    const promise = new CancellablePromise(async (resolve, reject) => {
      const token = await LoginService.getStoredAccessToken()
      let response: Response
      try {
        response = await fetch(url.toString(), {
          method: 'GET',
          signal: promise.abortController.signal,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...getIsInternalUserHeader(),
          },
        })
      } catch (e) {
        reject(e)
        return
      }

      if (response.status >= 200 && response.status < 300) {
        if (!promise.abortController.signal.aborted) {
          resolve(await response.blob())
          return
        } else {
          reject(new DOMException('Request was aborted'))
          return
        }
      }

      if (promise.abortController.signal.aborted) {
        reject(new DOMException('Request was aborted'))
        return
      }

      reject(new Error((await response.json())?.detail))
    })

    return promise as CancellablePromise<Blob>
  }

  private static async handleResponse<T>(
    response: Response | null,
    abortController: AbortController,
    resolve: (value?: T | PromiseLike<T>) => void,
    reject: (reason?: any) => void
  ) {
    if (!response) {
      reject(
        new FetchError({
          status: 404,
          title: 'No cached response found for the provided url.',
        })
      )

      return
    }

    if (response.status === 200) {
      if (!abortController.signal.aborted) {
        const result: T = await response.json()
        resolve(result)
        return
      } else {
        reject(new DOMException('Request was aborted'))
        return
      }
    }

    if (abortController.signal.aborted) {
      reject(new DOMException('Request was aborted'))
      return
    }

    try {
      const problemDetails: ProblemDetails = await response.json()
      const err = new FetchError(problemDetails)
      reject(err)
      return
    } catch (e) {
      reject(
        new FetchError({
          status: response.status,
          title: 'Something went wrong.',
        })
      )

      return
    }
  }
  public static apiGet<T>(url: URL | string): CancellablePromise<T> {
    const abortController = new AbortController()

    const promise = new CancellablePromise<T>(async (resolve, reject) => {
      const token = await LoginService.getStoredAccessToken()
      let response: Response
      try {
        response = await fetch(url.toString(), {
          method: 'GET',
          signal: abortController.signal,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...getIsInternalUserHeader(),
          },
        })
      } catch (e) {
        reject(e)
        return
      }
      this.handleResponse(response, abortController, resolve, reject)
    })

    return promise
  }

  public static cacheGet<T>(
    url: URL | string,
    cache: Cache
  ): CancellablePromise<T> {
    const abortController = new AbortController()

    const fetcherror = new FetchError({
      status: 404,
      title: 'No cached response found for the provided url.',
    })

    const promise = new CancellablePromise<T>(async (resolve, reject) => {
      let response: Response
      try {
        response = await cache.match(url.toString())
      } catch (e) {
        reject(e)
        return
      }

      if (!response) {
        reject(fetcherror)

        return
      }

      if (!abortController.signal.aborted) {
        const [date, result]: [number, T] = await response.json()

        if (!date || date < new Date().getTime()) {
          cache.delete(url.toString())
          reject(fetcherror)
          return
        }

        resolve(result)
        return
      } else {
        reject(new DOMException('Request was aborted'))
        return
      }
    })

    return promise
  }

  private static async apiPatch<T, U>(url: URL | string, body: T): Promise<U> {
    const token = await LoginService.getStoredAccessToken()
    const response = await fetch(url.toString(), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        ...getIsInternalUserHeader(),
      },
      body: JSON.stringify(body),
    })
    if (response.status <= 300 && response.status >= 200) {
      const reader = response.body.getReader()
      let value: ReadableStreamReadResult<Uint8Array> = null
      let result = new Uint8Array(0)

      while ((value = await reader.read())) {
        if (value.done) break
        result = new Uint8Array([...result, ...value.value])
      }

      if (result.byteLength) {
        const json = JSON.parse(Buffer.from(result).toString('utf8'))
        return json
      }

      return
    }

    let error = await response.json()

    if (response.status == 402) {
      error = JSON.parse(error.detail)
    }

    let errorMessage: string = `${error.title ? error.title + '\n: ' : ''}${
      error.detail ? error.detail + '\n' : ''
    }`

    if(!errorMessage){
      errorMessage = `${error.Title ? error.Title + '\n: ' : ''}${
        error.Detail ? error.Detail + '\n' : ''
      }`
    }

    if (error.errors)
      for (let key in error.errors) {
        const value = error.errors[key]
        if (value) errorMessage += `${value}\n`
      }
    else if (error.Error?.errors)
      for (let err of error.Error.errors) {
        const errorBody = JSON.parse(err.errorMessage)
        for (let errBody of errorBody.Error.errors) {
          const message = errBody.errorMessage
          if (message) errorMessage += `${message}\n`
        }
      }

    throw new Error(errorMessage ?? error)
  }

  static async requestPasswordResetLink(username: string) {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/reset-password/${username}`,
      {
        method: 'GET',
      }
    )

    if (!response.ok) throw new Error(response.statusText)
  }

  static async requestPasswordReset(model: {
    username: string
    newPassword: string
    token: string
  }) {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/reset-password`,
      {
        method: 'POST',
        body: JSON.stringify(model),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    if (!response.ok) throw new Error(response.statusText)
  }

  private static async apiPost<T, U>(url: URL | string, body: T): Promise<U> {
    const token = await LoginService.getStoredAccessToken()
    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        ...getIsInternalUserHeader(),
      },
      body: JSON.stringify(body),
    })
    if (response.status <= 300 && response.status >= 200) {
      const reader = response.body.getReader()
      let value: ReadableStreamReadResult<Uint8Array> = null
      let result = new Uint8Array(0)

      while ((value = await reader.read())) {
        if (value.done) break
        result = new Uint8Array([...result, ...value.value])
      }

      if (result.byteLength) {
        const json = JSON.parse(Buffer.from(result).toString('utf8'))
        return json
      }

      return
    }

    let error = await response.json()
    debugger
    if (response.status == 402) {
      try {
        const parsedError = JSON.parse(error.detail)
        error = parsedError
      } catch {}
    }

    let errorMessage: string = `${error.title ? error.title + '\n: ' : ''}${
      error.detail ? error.detail + '\n' : ''
    }`

    if(!errorMessage){
      errorMessage = `${error.Title ? error.Title + '\n: ' : ''}${
        error.Detail ? error.Detail + '\n' : ''
      }`
    }


    if (error.errors)
      for (let key in error.errors) {
        const value = error.errors[key]
        if (value) errorMessage += `${value}\n`
      }
    else if (error.Error?.errors)
      for (let err of error.Error.errors) {
        const errorBody = JSON.parse(err.errorMessage)
        for (let errBody of errorBody.Error.errors) {
          const message = errBody.errorMessage
          if (message) errorMessage += `${message}\n`
        }
      }

    throw new Error(errorMessage ?? error)
  }

  private static async apiPut<T, U>(url: URL | string, body: T): Promise<U> {
    const token = await LoginService.getStoredAccessToken()
    const response = await fetch(url.toString(), {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        ...getIsInternalUserHeader(),
      },
      body: JSON.stringify(body),
    })
    if (response.status <= 300 && response.status >= 200) {
      const reader = response.body.getReader()
      let value: ReadableStreamReadResult<Uint8Array> = null
      let result = new Uint8Array(0)

      while ((value = await reader.read())) {
        if (value.done) break
        result = new Uint8Array([...result, ...value.value])
      }

      if (result.byteLength) {
        const json = JSON.parse(Buffer.from(result).toString('utf8'))
        return json
      }

      return
    }

    const error = await response.json()

    let errorMessage: string = `${error.title ? error.title + '\n: ' : ''}${
      error.detail ? error.detail + '\n' : ''
    }`

    if(!errorMessage){
      errorMessage = `${error.Title ? error.Title + '\n: ' : ''}${
        error.Detail ? error.Detail + '\n' : ''
      }`
    }


    if (error.errors)
      for (let key in error.errors) {
        const value = error.errors[key]
        if (value) errorMessage += `${error.errors[key]}\n`
      }

    throw new Error(errorMessage ?? error)
  }

  static getSalesInvoices(
    query: Partial<TInvoiceQuery>
  ): CancellablePromise<TSalesInvoice> {
    const promise = new CancellablePromise<TSalesInvoice>(
      async (resolve, reject) => {
        const url = new URL(
          `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/invoices` +
            serialize(query)
        )

        const token = await LoginService.getStoredAccessToken()
        const response = await fetch(url.toString(), {
          method: 'GET',
          signal: promise.abortController.signal,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...getIsInternalUserHeader(),
          },
        })

        if (promise.abortController.signal.aborted) {
          reject(new DOMException('Request aborted'))
          return
        }

        const json = await response.json()

        if (promise.abortController.signal.aborted) {
          reject(new DOMException('Request aborted'))
          return
        }

        if (response.status >= 200 && response.status < 300) resolve(json)
        else if (response.status === 404) return resolve(null)

        reject(
          new Error(json.Error?.errors?.map(x => x.errorMessage).join(','))
        )
      }
    )

    return promise
  }

  static getOrders(
    divisionIDs: number[],
    customerIDs: number[]
  ): CancellablePromise<TSalesOrders> {
    const promise = new CancellablePromise<TSalesOrders>(
      async (resolve, reject) => {
        const url = new URL(
          `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/SalesOrder`
        )

        const params = divisionIDs.some(x => x)
          ? divisionIDs.map(id => ['divisionIDs', id.toString()])
          : customerIDs.map(id => ['customerIDs', id.toString()])

        url.search = new URLSearchParams(params).toString()

        const token = await LoginService.getStoredAccessToken()
        const response = await fetch(url.toString(), {
          method: 'GET',
          signal: promise.abortController.signal,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...getIsInternalUserHeader(),
          },
        })

        if (promise.abortController.signal.aborted) {
          reject(new DOMException('Request aborted'))
          return
        }

        const json = await response.json()

        if (promise.abortController.signal.aborted) {
          reject(new DOMException('Request aborted'))
          return
        }

        if (response.status === 200) return resolve(json)
        if (response.status === 500) reject(new Error('No sales orders found.'))

        reject(new Error(json))
      }
    )

    return promise
  }

  static getFinancialDashboardData(
    query: Partial<TGetSpendingQuery>
  ): CancellablePromise<TSpendingEntity[]> {
    const promise = new CancellablePromise<TSpendingEntity[]>(
      async (resolve, reject) => {
        const token = await LoginService.getStoredAccessToken()

        const url = new URL(
          `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Spending` +
            serialize(query)
        )

        let response
        try {
          response = await fetch(url.toString(), {
            method: 'GET',
            signal: promise.abortController.signal,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              ...getIsInternalUserHeader(),
            },
          })
        } catch (e) {
          reject(e)
          return
        }
        if (promise.abortController.signal.aborted) {
          reject(new DOMException('Request aborted'))
          return
        }
        if (response?.status >= 300) {
          reject(new Error())
          return
        }

        const json = await response?.json()

        if (promise.abortController.signal.aborted) {
          reject(new DOMException('Request aborted'))
          return
        }

        resolve(json)
      }
    )

    return promise
  }

  static async postPayment(
    post: TInvoicesPayment
  ): Promise<TInvoicePaymentStatus[]> {
    return await APIService.apiPost<TInvoicesPayment, TInvoicePaymentStatus[]>(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Invoices/Pay/CreditCard`,
      post
    )
  }

  static orderSuppliesWithCreditCard(
    post: TSupplyOrderCreditCard
  ): Promise<TSupplyOrderResponse> {
    return APIService.apiPost<TSupplyOrderCreditCard, TSupplyOrderResponse>(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/OrderSupplies/CreditCard`,
      post
    )
  }

  static async orderSuppliesWithPurchaseOrder(
    post: TSupplyOrderPurchaseOrder
  ): Promise<TSupplyOrderResponse> {
    return await APIService.apiPost<
      TSupplyOrderPurchaseOrder,
      TSupplyOrderResponse
    >(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/OrderSupplies/PurchaseOrder`,
      post
    )
  }

  static async orderSuppliesFree(
    post: TSupplyOrderFree
  ): Promise<TSupplyOrderResponse> {
    return await APIService.apiPost<TSupplyOrderFree, TSupplyOrderResponse>(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/OrderSupplies/Free`,
      post
    )
  }

  static async postPurchaseOrder(
    post: TPurchaseOrder
  ): Promise<TInvoicePaymentStatus[]> {
    return await APIService.apiPost<TPurchaseOrder, TInvoicePaymentStatus[]>(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/PurchaseOrder`,
      post
    )
  }

  static async putCustomerProductPricing(
    post: TCustomerProductPricing
  ): Promise<TCustomerProductPricing> {
    return await APIService.apiPut<
      TCustomerProductPricing,
      TCustomerProductPricing
    >(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/CustomerProductPricing`,
      post
    )
  }

  static async putCustomerProductGroups(
    post: TCustomerProductGroupEditing
  ): Promise<TCustomerProductGroup> {
    return await APIService.apiPut<
      TCustomerProductGroupEditing,
      TCustomerProductGroup
    >(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/CustomerProductGroup`,
      post
    )
  }

  static getCustomerProductGroups(
    query: CustomerProductGroupQuery
  ): CancellablePromise<TCustomerProductGroup[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/CustomerProductGroup` +
        serialize(query)
    )

    return APIService.apiGet<TCustomerProductGroup[]>(url)
  }

  static getProductGroups(): CancellablePromise<ProductGroup[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/ProductGroup`
    )

    return APIService.apiGet<ProductGroup[]>(url)
  }

  static getCustomersAsync(
    query: GetCustomersQuery
  ): CancellablePromise<GetCustomersResponse> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Customer` +
        serialize(query)
    )

    return APIService.apiGet<GetCustomersResponse>(url)
  }

  static getCustomerByEncryptedBottleIdentifierAsync(
    query: GetCustomerByEncryptedBottleIdentifierQuery
  ): CancellablePromise<CustomerResponseDTO> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Customer/EncryptedBottle` +
        serialize(query)
    )

    return APIService.apiGet<CustomerResponseDTO>(url)
  }

  static getCustomerPrimaryContactByEncryptedBottleIdentifierAsync(
    query: GetCustomerPrimaryContactByEncryptedBottleIdentifierQuery
  ): CancellablePromise<ContactDTO> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Contact/Customer/Primary/EncryptedBottle` +
        serialize(query)
    )

    return APIService.apiGet<ContactDTO>(url)
  }

  static getCustomerPrimaryContactAsync(
    query: GetCustomerPrimaryContact
  ): CancellablePromise<ContactDTO> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Contact/Customer/Primary` +
        serialize(query)
    )

    return APIService.apiGet<ContactDTO>(url)
  }

  static getContactsByEncryptedBottleIdentifierAsync(
    query: GetContactsByEncryptedBottleIdentifierQuery
  ): CancellablePromise<GetContactsResponse> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Contact/EncryptedBottle` +
        serialize(query)
    )

    return APIService.apiGet<GetContactsResponse>(url)
  }

  static getContactsAsync(
    query: GetContactsQuery
  ): CancellablePromise<GetContactsResponse> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Contact` +
        serialize(query)
    )

    return APIService.apiGet<GetContactsResponse>(url)
  }

  static getPlantsByEncryptedBottleAsync(
    query: TGetPlantsByEncryptedBottleQuery
  ): CancellablePromise<GetPlantsResponse> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Plant/EncryptedBottle` +
        serialize(query)
    )

    return APIService.apiGet<GetPlantsResponse>(url)
  }

  static getPlantsAsync(
    query: GetPlantsQuery
  ): CancellablePromise<GetPlantsResponse> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Plant` +
        serialize(query)
    )

    return APIService.apiGet<GetPlantsResponse>(url)
  }

  static getBillingPlantsAsync(
    query: GetBillingPlantsQuery
  ): CancellablePromise<GetBillingPlantsResponse> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/BillingPlant` +
        serialize(query)
    )

    return APIService.apiGet<GetBillingPlantsResponse>(url)
  }

  static getMachinesWithEncryptedBottleIdentifierAsync(
    query: GetMachinesWithEncryptedBottleIdentifierQuery
  ): CancellablePromise<GetMachinesResponse> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Machine/EncryptedBottle` +
        serialize(query)
    )

    return APIService.apiGet<GetMachinesResponse>(url)
  }

  static getMachinesAsync(
    query: GetMachinesQuery
  ): CancellablePromise<GetMachinesResponse> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Machine` +
        serialize(query)
    )

    return APIService.apiGet<GetMachinesResponse>(url)
  }

  static getBottleStatusAsync(
    query: GetBottleStatusQuery
  ): CancellablePromise<BottleStatusDetails> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Bottle/Status` +
        serialize(query)
    )

    return APIService.apiGet<BottleStatusDetails>(url)
  }

  static getEncryptedBottleStatusAsync(
    query: GetEncryptedBottleStatusQuery
  ): CancellablePromise<BottleStatusDetails> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Bottle/Encrypted/Status` +
        serialize(query)
    )

    return APIService.apiGet<BottleStatusDetails>(url)
  }

  static getBottlesAsync(
    query: GetBottlesQuery
  ): CancellablePromise<GetBottlesResponse> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Bottle` +
        serialize(query)
    )

    return APIService.apiGet<GetBottlesResponse>(url)
  }

  static getEncryptedBottlesAsync(
    query: GetEncryptedBottlesQuery
  ): CancellablePromise<GetEncryptedBottlesResponse> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Bottle/Encrypted` +
        serialize(query)
    )

    return APIService.apiGet<GetEncryptedBottlesResponse>(url)
  }

  static getMachineDetailAsync(
    query: GetMachineDetail
  ): CancellablePromise<PostMachinesDTO> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Machine/Detail` +
        serialize(query)
    )

    return APIService.apiGet<PostMachinesDTO>(url)
  }

  static getMachineDetailEncryptedAsync(
    query: GetMachineDetailEncrypted
  ): CancellablePromise<PostMachinesDTO> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Machine/Detail/Encrypted` +
        serialize(query)
    )

    return APIService.apiGet<PostMachinesDTO>(url)
  }

  static patchUserPassword(patch: ChangeUserPassword): Promise<any> {
    return APIService.apiPatch<ChangeUserPassword, any>(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Contact`,
      patch
    )
  }

  static patchBottlesAsync(
    patch: PatchBottleQuery
  ): Promise<PatchBottlesResponse> {
    return APIService.apiPatch<PatchBottleQuery, PatchBottlesResponse>(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Bottle`,
      patch
    )
  }

  static patchEncryptedBottlesAsync(
    patch: PatchEncryptedBottleQuery
  ): Promise<PatchEncryptedBottlesResponse> {
    return APIService.apiPatch<
      PatchEncryptedBottleQuery,
      PatchEncryptedBottlesResponse
    >(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Bottle/Encrypted`,
      patch
    )
  }

  static postPlantsByEncryptedBottleAsync(
    post: PostPlantsByEncryptedBottleQuery
  ): Promise<PostPlantsResponse> {
    return APIService.apiPost<
      PostPlantsByEncryptedBottleQuery,
      PostPlantsResponse
    >(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Plant/EncryptedBottle`,
      post
    )
  }

  static postPlantsAsync(post: PostPlantsQuery): Promise<PostPlantsResponse> {
    return APIService.apiPost<PostPlantsQuery, PostPlantsResponse>(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Plant`,
      post
    )
  }

  static postMachinesAsync(
    post: PostMachinesQuery
  ): Promise<PostMachinesResponse> {
    return APIService.apiPost<PostMachinesQuery, PostMachinesResponse>(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Machine`,
      post
    )
  }

  static patchMachinesByEncryptedBottleAsync(
    post: PatchEncryptedMachinesQuery
  ): Promise<PostMachinesResponse> {
    return APIService.apiPatch<
      PatchEncryptedMachinesQuery,
      PostMachinesResponse
    >(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Machine/Encrypted`,
      post
    )
  }

  static patchMachinesAsync(
    post: PatchMachinesQuery
  ): Promise<PostMachinesResponse> {
    return APIService.apiPatch<PatchMachinesQuery, PostMachinesResponse>(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Machine`,
      post
    )
  }

  static postMachinesByEncryptedBottleAsync(
    post: PostEncryptedMachinesQuery
  ): Promise<PostMachinesResponse> {
    return APIService.apiPost<PostEncryptedMachinesQuery, PostMachinesResponse>(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Machine/Encrypted`,
      post
    )
  }

  static putEncryptedWarhammerSamples(
    put: PutEncryptedWarhammerSamples
  ): Promise<EncryptedWarhammerSample[]> {
    return APIService.apiPut<
      PutEncryptedWarhammerSamples,
      EncryptedWarhammerSample[]
    >(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Warhammer/Sample/Encrypted`,
      put
    )
  }

  static putWarhammerSamples(
    put: PutWarhammerSamples
  ): Promise<WarhammerSample[]> {
    return APIService.apiPut<PutWarhammerSamples, WarhammerSample[]>(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Warhammer/Sample`,
      put
    )
  }

  static getEncryptedWarhammerSamplesAsync(
    query: GetEncryptedWarhammerSamples
  ): CancellablePromise<EncryptedWarhammerSample[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Warhammer/Sample/Encrypted` +
        serialize(query)
    )

    return APIService.apiGet<EncryptedWarhammerSample[]>(url)
  }

  static getWarhammerSamplesAsync(
    query: GetWarhammerSamples
  ): CancellablePromise<WarhammerSample[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Warhammer/Sample` +
        serialize(query)
    )

    return APIService.apiGet<WarhammerSample[]>(url)
  }

  static getEncryptedRoutesAsync(
    query: GetRoutesEncrypted
  ): CancellablePromise<Route[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Route/Encrypted` +
        serialize(query)
    )

    return APIService.apiGet<Route[]>(url)
  }

  static getRoutesAsync(): CancellablePromise<Route[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Route`
    )

    return APIService.apiGet<Route[]>(url)
  }

  static getSamplesAsync(query: GetSamples): CancellablePromise<Sample[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Sample` +
        serialize(query)
    )

    return APIService.apiGet<Sample[]>(url)
  }

  static getAllFluidTypesEncryptedAsync(
    query: GetAllFluidManufacturersEncrypted
  ): CancellablePromise<FluidType[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Fluid/Types/All/Encrypted` +
        serialize(query)
    )

    return APIService.apiGet<FluidType[]>(url)
  }

  static getAllFluidTypesAsync(): CancellablePromise<FluidType[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Fluid/Types/All`
    )

    return APIService.apiGet<FluidType[]>(url)
  }

  static getFluidDetailsAsync(
    query: GetFluidDetails
  ): CancellablePromise<FluidDetails> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Fluid/Details` +
        serialize(query)
    )

    return APIService.apiGet<FluidDetails>(url)
  }

  static getFluidDetailsEncryptedAsync(
    query: GetFluidDetailsEncrypted
  ): CancellablePromise<FluidDetails> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Fluid/Details/Encrypted` +
        serialize(query)
    )

    return APIService.apiGet<FluidDetails>(url)
  }

  static getAllFluidManufacturersEncryptedAsync(
    query: GetAllFluidManufacturersEncrypted
  ): CancellablePromise<TListType[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Fluid/Manufacturers/All/Encrypted` +
        serialize(query)
    )

    return APIService.apiGet<TListType[]>(url)
  }

  static getAllFluidManufacturersAsync(): CancellablePromise<TListType[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Fluid/Manufacturers/All`
    )

    return APIService.apiGet<TListType[]>(url)
  }

  static getAllCustomerPricingEncryptedAsync(
    query: GetAllCustomerPricingEncrypted
  ): CancellablePromise<TCustomerTestGroupPricing[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Customer/TestGroupPricing/All/Encrypted` +
        serialize(query)
    )

    return APIService.apiGet<TCustomerTestGroupPricing[]>(url)
  }

  static getAllCustomerPricingAsync(): CancellablePromise<
    TCustomerTestGroupPricing[]
  > {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Customer/Manufacturers/All`
    )

    return APIService.apiGet<TCustomerTestGroupPricing[]>(url)
  }

  static getAllMachinePortTypesEncryptedAsync(
    query: GetAllMachinePortTypesEncrypted
  ): CancellablePromise<TIdentityType[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Machine/PortTypes/All/Encrypted` +
        serialize(query)
    )

    return APIService.apiGet<TIdentityType[]>(url)
  }
  static getAllMachinePortTypesAsync(): CancellablePromise<TIdentityType[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Machine/PortTypes/All`
    )

    return APIService.apiGet<TIdentityType[]>(url)
  }
  static getAllMachineTypesEncryptedAsync(
    query: GetAllMachineTypesEncrypted
  ): CancellablePromise<TListType[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Machine/Types/All/Encrypted` +
        serialize(query)
    )

    return APIService.apiGet<TListType[]>(url)
  }

  static getAllMachineTypesAsync(): CancellablePromise<TListType[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Machine/Types/All`
    )

    return APIService.apiGet<TListType[]>(url)
  }

  static getAllMachineManufacturersEncryptedAsync(
    query: GetAllMachineManufacturersEncrypted
  ): CancellablePromise<TListType[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Machine/Manufacturers/All/Encrypted` +
        serialize(query)
    )

    return APIService.apiGet<TListType[]>(url)
  }

  static getAllMachineManufacturersAsync(): CancellablePromise<TListType[]> {
    const url = new URL(
      `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Machine/Manufacturers/All`
    )

    return APIService.apiGet<TListType[]>(url)
  }
}
